import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import Authors from "./Authors"
import RelatedPapers from "./RelatedPapers"
import { MainBackground } from "../MainBackground"
import TOC from "./TOC"
import ReactFlagsSelect from "react-flags-select"
import styled from "styled-components"
import Abstract from "./Abstract"
import ResearchSidebar from "./ResearchSidebar"
import htmlSerializer from "../../utils/htmlSerializer"
import { Container } from "../Container"
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";
import WrittenBy from "../WrittenBy/WrittenBy";
import {Icon} from "../Icon";
import sponsors1 from '../../assets/images/sponsors/checkpoint.svg';
import sponsors2 from '../../assets/images/sponsors/rgis.svg';
import sponsors3 from '../../assets/images/sponsors/axon.svg';
import sponsors4 from '../../assets/images/sponsors/retail.svg';
import sponsors5 from '../../assets/images/sponsors/ncr.svg';
import sponsors7 from '../../assets/images/sponsors/vision.svg';

const Research = props => {
  const {
    title,
    subtitle,
    content,
    grantProvidersBoxTitle,
    relatedPapersToxTitle,
    authors,
    grantProviders,
    relatedPapers,
    toc,
    file,
    abstract,
    category,
    location,
  } = props
  const pageURL = ""
  const [selected, setSelected] = useState("US")
  const [selectedLang, setSelectedLang] = useState("english")
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [download, setDownload] = useState(false);
  const [top, setTop] = useState(false);
  const fromUrl = new URLSearchParams(location?.search).get("from")
  const breadCrumbs = [
    { label: "Return to all reports", linkUrl: fromUrl || "/research" },
    {
      label: `${category?.document?.data?.name}`,
      linkUrl: `/category/${category?.uid}`,
    },
  ]
  const selectLanguage = code => {
    const language = {
      US: "english",
      FR: "french",
      ES: "spanish",
      DE: "german",
      NL: "dutch",
    }
    return language[code] || "english"
  }
  const onChangeLang = code => {
    setSelectedLang(selectLanguage(code))
    setSelected(code)
  }
  const onSetSidebarOpen = open => {
    setSidebarOpen(open)
  }
  const handleScroll = () => {
    if ((window.scrollY > 1500) && (!download || !top)) {
      setDownload(true);
      setTop(true);
    } else {
      setTop(false);
    }
  };
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDownload(true);
    }, 30000);
    window.addEventListener("scroll", handleScroll);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
      </style>
      <ResearchSidebar
        formId="download"
        pageURL={pageURL}
        downloadURL={file}
        dataLayerEvent={{ event: "download" }}
        sidebarOpen={sidebarOpen}
        onSetSidebarOpen={onSetSidebarOpen}
      />
      <MainBackground type={"violet"} container>
        <Wrapper>
          <Container>
            <BreadCrumbs list={breadCrumbs} />
            <TextWrapper>
              <div dangerouslySetInnerHTML={{ __html: title.html }} />
              <h3>{subtitle}</h3>
            </TextWrapper>
            <WrittenBy>
              <Authors
                authors={authors}
              />
            </WrittenBy>
            <HeaderNavigationWrapper>
              <TocWrap>
                <TOC data={toc} />
                {abstract.length > 0 && (
                  <FlagsWrap>
                    <h5 id="abstract">Languages :</h5>
                    <ReactFlagsSelect
                      selected={selected}
                      onSelect={code => onChangeLang(code)}
                      countries={["US", "FR", "ES", "DE", "NL"]}
                    />
                  </FlagsWrap>
                )}
              </TocWrap>
            </HeaderNavigationWrapper>
          </Container>
          <ArticleWrapper>
            <Icon type="LAYER"/>
            <Container>
              {download && <DownloadBtn onClick={() => onSetSidebarOpen(true)}>
                <Icon type="DOWNLOAD_ICON"/>
              </DownloadBtn>}
              {top && <TopBtn onClick={scrollTop}>
                <Icon type="SCROLL_TOP_BLOCK_WHITE"/>
              </TopBtn>}
              <RelatedPapers papers={relatedPapers} />
              {abstract.length > 0 && (
                <div className={'abstract_text'}>
                  <Abstract abstract={abstract} selectedLang={selectedLang} />
                </div>
              )}
              <RichTextContainer color={category?.document?.data?.color}>
                <RichText
                  render={content.raw}
                  htmlSerializer={htmlSerializer}
                />
              </RichTextContainer>
              <WrittenBy black={true}>
                <Authors
                  authors={authors}
                  category={category}
                  grantProvidersBoxTitle={grantProvidersBoxTitle}
                  grantProviders={grantProviders}
                  relatedPapersToxTitle={relatedPapersToxTitle}
                />
              </WrittenBy>
              <div>
                <SponsorsWrapper>
                  Research Grant Providers:
                </SponsorsWrapper>
                <SponsorsList>
                  <img src={sponsors5} alt="sponsor"/>
                  <img src={sponsors4} alt="sponsor"/>
                  <img src={sponsors3} alt="sponsor"/>
                  <img src={sponsors2} alt="sponsor"/>
                  <img src={sponsors1} alt="sponsor"/>
                  <img src={sponsors7} alt="sponsor"/>
                </SponsorsList>
              </div>
            </Container>
          </ArticleWrapper>
        </Wrapper>
      </MainBackground>
    </>
  )
}

Research.propTypes = {
  title: PropTypes.object.isRequired,
  cover: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
  authorsBoxTitle: PropTypes.string.isRequired,
  grantProvidersBoxTitle: PropTypes.string.isRequired,
  relatedPapersToxTitle: PropTypes.string,
  authors: PropTypes.array.isRequired,
  grantProviders: PropTypes.array.isRequired,
  relatedPapers: PropTypes.array.isRequired,
  toc: PropTypes.array.isRequired,
}

export default Research

const HeaderNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0 20px;
  margin-bottom: 20px;
  @media (min-width: 577px) {
    flex-direction: row;
  }
`
const SponsorsList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #473974;
  padding: 15px;
  img {
    max-width: 170px;
    margin: 10px 0;
  }
  @media (max-width: 1120px) {
    img {
      max-width: 48%;
    }
  }
  @media (max-width: 1120px) {
    div {
      margin: 10px;
    }
  }
`
const SponsorsWrapper = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  display: flex;
  font-size: 20px;
  align-items: center;
  svg {
    margin-left: 10px;
  }
`
const ArticleWrapper = styled.div`
  background: rgb(249,249,249);
  color: black;
  position: relative;
  padding-top: 16px;
  padding-bottom: 20px;
  &>svg {
    position: absolute;
    right: 0;
    max-width: 100%;
  }
`
const RichTextContainer = styled.div`
  & * {
    font-family: "Roboto", sans-serif!important;
    color: rgb(62,62,62);
  }
  img {
    max-width: 600px;
    display: block;
    margin: 0 auto;
  }
  h2 {
    text-transform: uppercase;
    color: ${props => props.color};
    margin-bottom: 23px;
  }
  p {
    font-weight: 500;
  }
  h3 {
    color: ${props => props.color};
  }
  ul, ol {
    padding: 0;
    margin-bottom: 32px;
    list-style: inside;
  }
  h4, h5, h6 {
    margin-bottom: 32px;
    font-size: 18px;
  }
  ol li, ul li {
    line-height: 30px;
    font-size: 18px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .embed iframe {
    width: 600px;
    height: 350px;
    margin: 0 auto 20px;
    max-width: 100%;
    display: block;
  }
  @media (max-width: 1024px) {
    ul, ol {
      margin-bottom: 15px;
    }
    ol li, ul li {
      font-size: 15px;
      line-height: 25px;
    }
    h4, h5, h6 {
      margin-bottom: 15px;
      font-size: 15px;
    }
  }
  @media (max-width: 640px) {
    .embed iframe {
      height: 300px;
    }
  }
`

const DownloadBtn = styled.button`
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  cursor: pointer;
  width: 55px;
  height: 55px;
  background: rgb(60,49,94);
  position: fixed;
  border-radius: 50%;
  bottom: 120px;
  right: 160px;
  :before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
    animation: blink 1.5s linear infinite;
    -webkit-box-shadow: 0px 0px 20px 5px rgba(78,147,230,1);
    -moz-box-shadow: 0px 0px 20px 5px rgba(78,147,230,1);
    box-shadow: 0px 0px 20px 5px rgba(78,147,230,1);
  }
  @media (max-width: 1024px) {
    bottom: 100px;
    width: 45px;
    height: 45px;
    right: 100px;
    svg {
      width: 20px;
    }
  }
  @media (max-width: 768px) {
    right: 30px;
  }
`

const TopBtn = styled.button`
  cursor: pointer;
  width: 55px;
  height: 55px;
  background: rgb(60,49,94);
  position: fixed;
  border-radius: 50%;
  bottom: 120px;
  right: 70px;
  z-index: 9999;
  :before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 50%;
  }
  @media (max-width: 1024px) {
    bottom: 100px;
    width: 45px;
    height: 45px;
    overflow: hidden;
    right: 30px;
    svg {
      position: absolute;
      top: -6px;
      left: -4px;
    }
  }
  @media (max-width: 768px) {
    bottom: 30px;
  }
`

const Wrapper = styled.section`
  position: relative;
  color: #fff;
`

const TextWrapper = styled.div`
  margin-bottom: 20px;

  h1 {
    line-height: calc(78 / 64);
    font-size: 26px;
    color: #fff;
    margin-bottom: 5px;
    text-transform: uppercase;

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 39px;
    }
  }
  h3 {
    color: #fff;
  }
  p {
    font-size: 12px;
    font-weight: bold;
    color: #999;
    line-height: 16px;
  }
  a {
    color: #fff;
  }
`

const TocWrap = styled.div`
  display: flex;
  @media (max-width: 640px) {
    flex-direction: column;
    width: 100%;
    div {
      margin: 0;
    }
  }
`

const FlagsWrap = styled.div`
  display: flex;
  margin-left: 124px;
  flex-wrap: wrap;
  align-items: center;
  > div {
    padding-bottom: 0;
    margin: 0;
  }
  h5 {
    font-size: 16px;
    margin: 0 !important;
    color: rgb(42,149,191);

    @media (min-width: ${({ theme }) => theme.media.tablet_min}) {
      font-size: 20px;
    }
  }
  button {
    font-size: 20px!important;
  }
  @media (max-width: 640px) {
    button {
      font-size: 16px!important;
    }
  }
  #rfs-btn {
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 0 10px;
    margin: 0;

    span {
      margin: 0;
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.9834 1.99996L11.9917 11.9917L1.99993 1.99997' stroke='white' stroke-width='3.07438'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      width: 15px;
      height: 10px;
    }
  }
  #rfs-btn[aria-expanded="true"] {
    &:after {
      transform: rotate(180deg);
    }
  }
  ul {
    background-color: #2a95bf;
    border: none;
    max-height: 300px;
    padding: 0;
    border-radius: 0;

    li {
      padding: 8px 8px 8px 0;
      margin: 0;
      font-size: 16px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        background: white;
        width: 0;
        height: 100%;
      }

      &:hover {
        background-color: #005475;

        &::before {
          width: 2px;
        }
      }
      span {
        flex-direction: row-reverse;
        justify-content: space-between;

        svg {
          width: 40px;
          height: 30px;
        }
      }
    }
  }
`
